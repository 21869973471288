export const routeData = [
  {
    day: "1",
    date: "28/4/2013",
    from: "इंदौर (म.प्र.)",
    via: "उज्जैन, देवास, सिहोर",
    to: "भोपाल (म.प्र.)",
  },
  {
    day: "2",
    date: "29/4/201",
    from: "भोपाल (म.प्र.)",
    via: "होशंगाबाद, इटारसी",
    to: "बेतुल (म.प्र.)",
  },
  {
    day: "3",
    date: "30/4/2013",
    from: "बेतुल (म.प्र.)",
    via: "नागपुर, भंडारा, राजनान्दगाँव",
    to: "रात्रि यात्र",
  },
  {
    day: "4",
    date: "1/5/2013",
    from: "रात्रि यात्रा",
    via: "",
    to: "भिलाई (छ.ग.)",
  },
  {
    day: "",
    date: "1/5/2013",
    from: "भिलाई (छ.ग.)",
    via: "रायपुर, सम्बलपुर",
    to: "रायपुर (छ.ग.)",
  },
  {
    day: "5",
    date: "2/5/2013",
    from: "रायपुर (छ.ग.)",
    via: "",
    to: "रात्रि यात्रा",
  },
  {
    day: "6",
    date: "3/5/2013",
    from: "रात्रि यात्रा",
    via: "",
    to: "केओंझर (उड़ीसा)",
  },
  {
    day: "",
    date: "3/5/2013",
    from: "केओंझर (उड़ीसा)",
    via: "खरगरपुर",
    to: "कोलकाता (प.ब.)",
  },
  {
    day: "7",
    date: "4/5/2013",
    from: "कोलकाता (प.ब.)",
    via: "रानाघाट, बेरहामपुर, इंग्लिशबाजार",
    to: "रात्रि यात्रा",
  },
  {
    day: "8",
    date: "5/5/2013",
    from: "रात्रि यात्रा",
    via: "",
    to: "रायगंज (प.ब.)",
  },
  {
    day: "9",
    date: "6/5/2013",
    from: "रायगंज (प.ब.)",
    via: "किशनगंज, इस्लामपुर, सिलीगुड़ी",
    to: "धूपगुरी (प.ब.)",
  },
  {
    day: "10",
    date: "7/5/2013",
    from: "धूपगुरी (प.ब.)",
    via: "मथुराबागान, अलीपुरद्वार, गोसईगाँव",
    to: "बोंगईगांव (आसाम)",
  },
  {
    day: "11",
    date: "8/5/2013",
    from: "बोंगईगांव (आसाम)",
    via: "नालबारी, ओरांग",
    to: "तेजपुर (आसाम)",
  },
  {
    day: "12",
    date: "9/5/2013",
    from: "तेजपुर (आसाम)",
    via: "जमुरीघाट, गोहपुर",
    to: "इटानगर (अरु.प्र.)",
  },
  {
    day: "13",
    date: "10/5/2013",
    from: "इटानगर (अरु.प्र.)",
    via: "नॉर्थ लखीमपुर, धेमाजी",
    to: "पासीघाट (अरु.प्र.)",
  },
  {
    day: "14",
    date: "11/5/2013",
    from: "पासीघाट (अरु.प्र.)",
    via: "&nbsp;<span>—</span>",
    to: "साड़ियाघाट (आसाम)",
  },
  {
    day: "15",
    date: "12/5/2013",
    from: "साड़ियाघाट (आसाम)",
    via: "तिनसुकिया, डिब्रुगढ़, सिवसागर",
    to: "जोरहाट (आसाम)",
  },
  {
    day: "16",
    date: "13/5/2013",
    from: "जोरहाट (आसाम)",
    via: "गोलाघाट, दिमापुर",
    to: "कोहिमा (नागा.)",
  },
  {
    day: "17",
    date: "14/5/2013",
    from: "कोहिमा (नागा.)",
    via: "सेनापति, सेकमाई बाजार",
    to: "इम्फ़ाल (मणि.)",
  },
  {
    day: "18",
    date: "15/5/2013",
    from: "इम्फ़ाल (मणि.)",
    via: "इम्फ़ाल-सिल्चर मार्ग",
    to: "शांतिखुनौ (मणि.)",
  },
  {
    day: "19",
    date: "16/5/2013",
    from: "शांतिखुनौ (मणि.)",
    via: "इम्फ़ाल-सिल्चर मार्ग",
    to: "सिल्चर (आसाम)",
  },
  {
    day: "20",
    date: "17/5/2013",
    from: "सिल्चर (आसाम)",
    via: "मिझोरम मार्ग",
    to: "वैरेंगटे (मिज़ो.)",
  },
  {
    day: "20",
    date: "17/5/2013",
    from: "वैरेंगटे (मिज़ो.)",
    via: "मिझोरम मार्ग",
    to: "सिल्चर (आसाम)",
  },
  {
    day: "21",
    date: "18/5/2013",
    from: "सिल्चर (आसाम)",
    via: "बदरपुर, करीमगंज, पानीसागर",
    to: "तेलियामुरा (त्रिपुरा)",
  },
  {
    day: "22",
    date: "19/5/2013",
    from: "तेलियामुरा (त्रिपुरा)",
    via: "&nbsp;<span>—</span>",
    to: "अगरतला (त्रिपुरा)",
  },
  {
    day: "22",
    date: "19/5/2013",
    from: "अगरतला (त्रिपुरा)",
    via: "तेलियामुरा, पानीसागर",
    to: "करीमगंज (आसाम)",
  },
  {
    day: "23",
    date: "20/5/2013",
    from: "करीमगंज (आसाम)",
    via: "बदरपुर, जोवाई",
    to: "शिलोंग (मेघा.)",
  },
  {
    day: "24",
    date: "21/5/2013",
    from: "शिलोंग (मेघा.)",
    via: "गुवाहाटी, अगिया",
    to: "छापर (आसाम)",
  },
  {
    day: "25",
    date: "22/5/2013",
    from: "छापर (आसाम)",
    via: "गौरीपुर, कूच-बिहार, मथुराबागान, नगराकाटा, मालबाजार",
    to: "रांगपो (सिक्किम)",
  },
  {
    day: "26",
    date: "23/5/2013",
    from: "रांगपो (सिक्किम)",
    via: "गंगटोक, रांगपो, तीस्ता बाजार, घूम",
    to: "दार्जिलिंग (प.ब.)",
  },
  {
    day: "27",
    date: "24/5/2013",
    from: "दार्जिलिंग (प.ब.)",
    via: "नक्सलबारी, ठाकुरगंज, बहादुरगंज, बसंतपुर, फोरबिसगंज",
    to: "सिमराही (बिहार)",
  },
  {
    day: "28",
    date: "25/5/2013",
    from: "सिमराही (बिहार)",
    via: "दरभंगा, गोपालगंज",
    to: "कुशीनगर (उ.प्र.)",
  },
  {
    day: "29",
    date: "26/5/2013",
    from: "कुशीनगर (उ.प्र.)",
    via: "गोरखपुर, बस्ती, अयोध्या",
    to: "लखनऊ (उ.प्र.)",
  },
  {
    day: "30",
    date: "27/5/2013",
    from: "लखनऊ (उ.प्र.)",
    via: "सीतापुर, शाहजहाँपुर",
    to: "बरेली (उ.प्र.)",
  },
  {
    day: "31",
    date: "28/5/2013",
    from: "बरेली (उ.प्र.)",
    via: "बदायु, सिकंदरा, अलीगढ़, टप्पल",
    to: "नई दिल्ली",
  },
  {
    day: "32",
    date: "29/5/2013",
    from: "नई दिल्ली",
    via: "&nbsp;<span>—</span>",
    to: "नई दिल्ली",
  },
  {
    day: "33",
    date: "30/5/2013",
    from: "नई दिल्ली",
    via: "गाजियाबाद, हापुर, मोरादाबाद",
    to: "रामपुर (उ.प्र.)",
  },
  {
    day: "34",
    date: "31/5/2013",
    from: "रामपुर (उ.प्र.)",
    via: "रुद्रपुर, हल्द्वानी, अल्मोरा",
    to: "रानीखेत",
  },
  {
    day: "35",
    date: "1/6/2013",
    from: "रानीखेत",
    via: "चौखुटिया, कर्णप्रयाग, चमोली",
    to: "गोपेश्वर",
  },
  {
    day: "36",
    date: "2/6/2013",
    from: "गोपेश्वर",
    via: "चमोली, जोशीमठ, बद्रीनाथ",
    to: "पिपलकोटी",
  },
  {
    day: "37",
    date: "3/6/2013",
    from: "पिपलकोटी",
    via: "रुद्रप्रयाग, श्रीनगर, ऋषिकेश",
    to: "देहरादून",
  },
  {
    day: "38",
    date: "4/6/2013",
    from: "देहरादून",
    via: "पोंटासाहिब, चंडीगढ़",
    to: "आनंदपुर साहिब (पंजाब)",
  },
  {
    day: "39",
    date: "5/6/2013",
    from: "आनंदपुर साहिब (पंजाब)",
    via: "किरतपुर, बिलासपुर, मंडी",
    to: "भुंतर (हि.प्र.)",
  },
  {
    day: "40",
    date: "6/6/2013",
    from: "भुंतर (हि.प्र.)",
    via: "कुल्लू, मनाली, रोहतांग पास",
    to: "केलौंग (हि.प्र.)",
  },
  {
    day: "41",
    date: "7/6/2013",
    from: "केलौंग (हि.प्र.)",
    via: "मनाली-लेह हाइवे",
    to: "पाँग (ज.क.)",
  },
  {
    day: "42",
    date: "8/6/2013",
    from: "पाँग (ज.क.)",
    via: "मनाली-लेह हाइवे",
    to: "लेह (ज.क.)",
  },
  {
    day: "43",
    date: "9/6/2013",
    from: "लेह (ज.क.)",
    via: "खार्दुंग-ला, लेह, लामायुरु",
    to: "कारगिल (ज.क.)",
  },
  {
    day: "44",
    date: "10/6/2013",
    from: "कारगिल (ज.क.)",
    via: "श्रीनगर, अनंतनाग, रामबन",
    to: "पटनीटाप (ज.क.)",
  },
  {
    day: "45",
    date: "11/6/2013",
    from: "पटनीटाप (ज.क.)",
    via: "ऊधमपुर, जम्मू, लखेनपुर",
    to: "कैलाशपुर (पंजाब)",
  },
  {
    day: "46",
    date: "12/6/2013",
    from: "कैलाशपुर (पंजाब)",
    via: "गुरदासपुर, डेरा बाबानानक, अटारी, अमृतसर, तरण-तारण",
    to: "झिरा (पंजाब)",
  },
  {
    day: "47",
    date: "13/6/2013",
    from: "झिरा (पंजाब)",
    via: "अबोहर, श्रीगंगानगर, सुरतगढ़",
    to: "महाजन (राज.)",
  },
  {
    day: "48",
    date: "14/6/2013",
    from: "महाजन (राज.)",
    via: "बीकानेर, फलोदी",
    to: "रामदेवरा (राज.)",
  },
  {
    day: "49",
    date: "15/6/2013",
    from: "रामदेवरा (राज.)",
    via: "जैसलमर, बारमेड",
    to: "सांचोर (राज.)",
  },
  {
    day: "50",
    date: "16/6/2013",
    from: "सांचोर (राज.)",
    via: "थराड, सुइगाम, भचाउ",
    to: "भुज़ (गुज.)",
  },
  {
    day: "51",
    date: "17/6/2013",
    from: "भुज़ (गुज.)",
    via: "नखत्राणा, लखपत, नारायण सरोवर, नलिया",
    to: "मांडवी (गुज.)",
  },
  {
    day: "52",
    date: "18/6/2013",
    from: "मांडवी (गुज.)",
    via: "अंजड़, भचाउ, जामनगर",
    to: "द्वारका (गुज.)",
  },
  {
    day: "53",
    date: "19/6/2013",
    from: "द्वारका (गुज.)",
    via: "पोरबंदर, सोमनाथ",
    to: "दीव (द.दी.)",
  },
  {
    day: "54",
    date: "20/6/2013",
    from: "दीव (द.दी.)",
    via: "महुवा, भावनगर, वातामान",
    to: "बरोदा (गुज़.)",
  },
  {
    day: "55",
    date: "21/6/2013",
    from: "बरोदा (गुज़.)",
    via: "भरूच, वापी, दमन",
    to: "सिलवासा (दा.न.ह.)",
  },
  {
    day: "56",
    date: "22/6/2013",
    from: "सिलवासा (दा.न.ह.)",
    via: "ठाणे, पनवेल, महाड़",
    to: "चिपलून (महा.)",
  },
  {
    day: "57",
    date: "23/6/2013",
    from: "चिपलून (महा.)",
    via: "पाली",
    to: "पंजिम (गोवा)",
  },
  {
    day: "58",
    date: "24/6/2013",
    from: "पंजिम (गोवा)",
    via: "अंकोला, भटकल, उडुपी",
    to: "मेंगलोर (कर्ना.)",
  },
  {
    day: "59",
    date: "25/6/2013",
    from: "मेंगलोर (कर्ना.)",
    via: "कसर्गोड़, माहे, कोझिगोड़",
    to: "वेलेंचेरी (केरल)",
  },
  {
    day: "60",
    date: "26/6/2013",
    from: "वेलेंचेरी (केरल)",
    via: "थ्रिसुर, कोच्चि, अलप्पुझा",
    to: "ओचिरा (केरल)",
  },
  {
    day: "61",
    date: "27/6/2013",
    from: "ओचिरा (केरल)",
    via: "कोल्लम, थिरुवनंतपुरम, नागरकोइल",
    to: "कन्याकुमारी (त.ना.)",
  },
  {
    day: "62",
    date: "28/6/2013",
    from: "कन्याकुमारी (त.ना.)",
    via: "तिरुनेलवेली, तूथूकुडी, रामानाथपुरम",
    to: "रामेश्वरम (त.ना.)",
  },
  {
    day: "63",
    date: "29/6/2013",
    from: "रामेश्वरम (त.ना.)",
    via: "नागोरे, चिदम्बरम",
    to: "पोण्डेचेरी",
  },
  {
    day: "64",
    date: "30/6/2013",
    from: "पोण्डेचेरी",
    via: "चेन्नई, नेल्लोरे",
    to: "ओंगोले (आ.प्र.)",
  },
  {
    day: "65",
    date: "1/7/2013",
    from: "ओंगोले (आ.प्र.)",
    via: "गुंटूर, विजयवाड़ा, राजामुन्द्री",
    to: "विशाखापट्टनम (आ.प्र.)",
  },
  {
    day: "66",
    date: "2/7/2013",
    from: "विशाखापट्टनम (आ.प्र.)",
    via: "श्रीकाकुलम, बेरहमपुर, भूबनेश्वर",
    to: "पूरी (ओड़ीसा)",
  },
  {
    day: "67",
    date: "3/7/2013",
    from: "पूरी (ओड़ीसा)",
    via: "भूबनेश्वर, कट्टक, भद्रक, बालासोर",
    to: "जालेश्वर (ओड़ीसा)",
  },
  {
    day: "68",
    date: "4/7/2013",
    from: "जालेश्वर (ओड़ीसा)",
    via: "खड़गपुर, हावड़ा, बर्धमान",
    to: "आसनसोल (प.ब.)",
  },
  {
    day: "69",
    date: "5/7/2013",
    from: "आसनसोल (प.ब.)",
    via: "धनबाद, वाराणसी, इलाहाबाद",
    to: "फ़तेहपुर (उ.प्र.)",
  },
  {
    day: "70",
    date: "6/7/2013",
    from: "फ़तेहपुर (उ.प्र.)",
    via: "कानपुर, झाँसी, सागर",
    to: "बेगमगंज (म.प्र.)",
  },
  {
    day: "71",
    date: "7/7/2013",
    from: "बेगमगंज (म.प्र.)",
    via: "रायसेन, भोपाल, देवास",
    to: "इंदौर (म.प्र.)",
  },
];
