<template>
  <div>
    <h1>
      परिक्रमा मार्ग
      <small>परिक्रमा मार्ग दिन-ब-दिन पूर्ण विवरण</small>
    </h1>
    <table>
      <thead>
        <tr>
          <th>दिन</th>
          <th>दिनांक</th>
          <th>स्थान से</th>
          <th>के रास्ते से</th>
          <th>स्थान तक</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(troute, index) of routeData" :key="index">
          <td data-th="दिन">{{ troute.day }}</td>
          <td data-th="दिनांक">{{ troute.date }}</td>
          <td data-th="स्थान से">{{ troute.from }}</td>
          <td data-th="के रास्ते से">{{ troute.via }}</td>
          <td data-th="स्थान तक">{{ troute.to }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { routeData } from "@/routeData";
export default {
  name: "TourRoute",
  data() {
    return {
      routeData,
    };
  },
};
</script>
<style scopped lang="scss">
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 1rem 0;
  th,
  td {
    padding: 1rem;
  }
  th {
    font-weight: 700;
    background: #075c84;
    color: #fff;
    text-align: center;
  }
  td {
    color: #43464b;
    font-size: 1rem;
    line-height: 20px;
    min-width: 120px;
  }
  tr:nth-child(odd) {
    background: #eaf2f5;
  }
  tr:nth-child(even) {
    background: #fff;
  }
  @media only screen and (max-width: 767px) {
    background: #333;
    margin-top: 1.5rem;
    td,
    th {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
    }
    &,
    tbody,
    td,
    th,
    thead,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border: 1px solid #ccc;
      border-bottom: 0;
    }
    th,
    thead {
      display: none;
    }
    td {
      border: none;
      border-bottom: 1px solid #ccc;
      position: relative;
      padding: 8px 8px 8px 35%;
      text-align: left !important;
      display: block;
      min-height: 30px;
      font-size: 0.95rem;

      &:first-child {
        padding-top: 0.5em;
      }
      &:last-child {
        padding-bottom: 0.5em;
      }
      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 6px;
        width: 30%;
        padding-right: 10px;
        white-space: normal;
        text-transform: initial;
        content: attr(data-th) ": ";
        font-weight: 700;
        display: inline-block;
      }
    }
  }
}
</style>
